<template>
  <el-form
    :label-position="'left'"
    label-width="150px"
    @submit.prevent="onSubmit"
    style="margin: 20px; width: 60%; min-width: 1000px"
  >
    <el-form-item label="subjectCourseId">
      <el-input v-model="material.subjectCourseId"> </el-input>
    </el-form-item>
    <el-form-item label="id">
      <el-input v-model="material.id" disabled> </el-input>
    </el-form-item>
    <el-form-item label="title">
      <el-input v-model="material.title"> </el-input>
    </el-form-item>
    <el-form-item label="材料内容">
      <aidaFeedEditor
        :openMd="true"
        :openHtml="true"
        :openCos="true"
        :openVod="false"
        :openPractice="false"
        v-model="material.content"
      ></aidaFeedEditor>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSave">保存</el-button>
      <el-button>取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { MarkdownFileType } from "@/util/EnumUtil";
import aidaFeedEditor from "@/component/aidaFeedEditor";
import msg from "@/util/msg.js";
//import { alertMessage } from "@/util/alertMessageUtil.js";
import { tikuApi } from "@/api/tikuApi";

export default {
  components: {
    aidaFeedEditor,
  },
  data() {
    return {
      pageStatus: "add",
      material: {
        subjectCourseId: 1,
        id: 0,
        title: "",
        content: [
          {
            unitType: MarkdownFileType.MARKDOWN,
            unitId: 0,
            index: 0,
            content: "",
            fileName: "",
            url: "",
            markDown: {
              content: "题目内容",
            },
          },
        ],
      },
    };
  },
  methods: {
    onSave() {
      tikuApi
        .addMaterial(this.material)
        .then((ret) => {
          console.log("ret", ret);
          let { code: code, msg: msgStr, data: data } = ret;
          if (code == 0) {
            msg.succeed("保存成功");
            console.log(data, msgStr);
            if (this.pageStatus == "add") {
              this.$router.push({
                name: "aida-tiku-material-add",
                query: {
                  id: data.id,
                  subjectCourseId: data.subjectCourseId,
                },
              });
            }
          } else {
            msg.error("保存失败");
          }
        })
        .catch((err) => {
          console.log(err);
          msg.error("保存失败");
        });
    },
  },
  mounted() {
    //this.contentCnt = this.choiceQuestion.content.length;
    //this.materialCnt = this.choiceQuestion.material.length;
    const id = this.$route.query.id;
    const subjectCourseId = this.$route.query.subjectCourseId;
    if (id && id > 0 && subjectCourseId && subjectCourseId > 0) {
      this.pageStatus = "edit";
      tikuApi.getMaterial(subjectCourseId, id).then((ret) => {
        console.log(ret);
        let { code: code, data: data } = ret;
        if (code == 0) {
          this.material = data;
        }
      });
    }
  },
  filters: {},
  watch: {},
};
</script>

<style lang="less" scoped>
.choice-item {
  margin-bottom: 5px;
}
</style>
